dmx.Component('route', {

    initialData: {
        params: {}, // Key/value pairs parsed from the URL corresponding to the dynamic segments of the path
        isExact: false,
        isMatch: false,
        loading: false, // for ajax loading
        path: '', // The path pattern used to match. Useful for building nested Routes
        url: '' // The matched portion of the URL. Useful for building nested Links
    },

    tag: 'div',

    attributes: {
        path: {
            type: String,
            default: '*'
        },

        exact: {
            type: Boolean,
            default: false
        },

        url: {
            type: String,
            default: null
        }
    },

    events: {
        show: Event,
        hide: Event,
        error: Event, // ajax load error
        unauthorized: Event,
        forbidden: Event,
        notfound: Event // ajax page not found
    },

    render: function(node) {
        //dmx.BaseComponent.prototype.render.call(this, node);

        this.template = document.createElement('div');
        this.template.innerHTML = this.$node.innerHTML;
        this.$node.innerHTML = '';
        this.$parse(this.template);

        this.keys = [];
        this.re = dmx.pathToRegexp(this.props.path, this.keys, {
            end: this.props.exact
        });

        this.xhr = new XMLHttpRequest();
        this.xhr.addEventListener('load', this.onload.bind(this));
        this.xhr.addEventListener('abort', this.onabort.bind(this));
        this.xhr.addEventListener('error', this.onerror.bind(this));
        this.xhr.addEventListener('timeout', this.ontimeout.bind(this));

        this.update();
    },

    update: function() {
        var path = window.location.pathname;
        var base = document.querySelector('meta[name="ac:base"]');
        var route = document.querySelector('meta[name="ac:route"]');

        if (base && base.content) {
            path = path.replace(base.content.replace(/\/$/, ''), '');
        }

        if (route && route.content) {
            path = path.replace(dmx.pathToRegexp(route.content, [], { end: false }), '').replace(/^(\/+)?/, '/');
        }

        var match = this.re.exec(path);

        this.set('path', this.props.path);
        this.set('isExact', !!this.props.exact);
        this.set('isMatch', !!match);

        if (match) {
            this.set('url', match[0]);
            this.set('params', this.keys.reduce(function(params, key, index) {
                params[key.name] = match[index + 1];
                return params;
            }, {}));

            if (this.props.url && this.props.url == this.loaded && this.data.loading) {
                // Url is loading
                return;
            }

            if (this.props.url && this.props.url != this.loaded) {
                // correctly destroy old content first
                this.$node.innerHTML = '';
                this.children.splice(0).forEach(function(child) {
                    child.$destroy();
                });

                this.set('loading', true);

                this.loaded = this.props.url;

                this.xhr.abort();

                this.xhr.open('GET', this.props.url);
                this.xhr.send();
            } else if (!this.template.parentNode) {
                this.$node.appendChild(this.template);
                this.dispatchEvent('show');
            }
        } else {
            this.xhr.abort();
            this.set('url', '');
            this.set('params', {});
            if (this.template.parentNode) {
                this.$node.removeChild(this.template);
                this.dispatchEvent('hide');
            }
        }
    },

    onload: function(event) {
        this.set('loading', false);
        if (this.xhr.status == 200 || this.xhr.status == 0) {
            this.template.innerHTML = this.xhr.responseText;
            this.$parse(this.template);
            this.$node.appendChild(this.template);
            this.dispatchEvent('show');
        } else {
            if (this.xhr.status == 401) {
                this.dispatchEvent('unauthorized');
            } else if (this.xhr.status == 403) {
                this.dispatchEvent('forbidden');
            } else if (this.xhr.status == 404) {
                this.dispatchEvent('notfound');
            } else {
                this.dispatchEvent('error');
            }
        }
    },

    onabort: function(event) {
        this.set('loading', false);
        //this.dispatchEvent('error');
    },

    onerror: function(event) {
        this.set('loading', false);
        this.dispatchEvent('error');
    },

    ontimeout: function(event) {
        this.set('loading', false);
        this.dispatchEvent('error');
    }

});
